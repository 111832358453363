/*
This file processes the information for the form and organizes it into what will be
sent to the mailserver.

Everything needs to be formatted correctly before it comes in to this FormMailer
 - formInfo - this is the information about 
*/
// const forms = ["#contactForm", "#quoteForm"];
const DEFAULT_SERVER_ERROR = "Unable to send form at this time.";

class FormMailer {
  _formData = {};
  _messageContainer = null;
  _form = "";

  constructor(form, formData = null) {
    this._formData = formData;
    this._form = form;

    this._createStyleObject();
    this._createMessageHtml();
    this._createEventHandler();
    if (!this._formData) return 1;
    return this;
  }

  _createStyleObject = () => {
    const style = document.createElement("style");

    style.textContent = `
      .response-container{
        position:relative;
        display: flex;
        justify-content: center;
      }
    
      .response-message{
        position: absolute;
        z-index: 9999 !important;
        opacity:0;
        border-radius: 0.375rem/* 6px */;
        border-radius: 0.375rem/* 6px */;
        padding-top: 1rem/* 16px */;
        padding-bottom: 1rem/* 16px */;
        padding-left: 1.25rem/* 20px */;
        padding-right: 1.25rem/* 20px */;
        
        transition: all .25s ease;
      }
      .rs-show{
        opacity: 1;
      }
    
      .rs-hide{
        display: none;
      }
    
      .rs-success{
        --tw-bg-opacity: 1;
        background-color: rgb(21 128 61 / var(--tw-bg-opacity));
      }
    
      .rs-fail {
        --tw-bg-opacity: 1;
        background-color: rgb(153 27 27 / var(--tw-bg-opacity));
      }
    `;

    document.head.appendChild(style);
  };

  _createEventHandler = () => {
    this._form.addEventListener("submit", async (event) => {
      event.preventDefault();
      this._createFormDataObject();

      const response = await this._sendContactForm();
      if (response.status === 200) {
        this._showResponse(response);
        this._clearFormEntries();
      } else this._showResponse(response);
    });
  };

  _createFormDataObject = () => {
    this._formData = {};
    this._formData["title"] = this._form.querySelector("#title") ? this._form.firstElementChild.textContent : "Contact Form";
    this._formIteration();
  };

  _formIteration = (create = true) => {
    Array.from(this._form.querySelectorAll("label")).forEach((el) => {
      const labelFor = el.htmlFor;
      const inputObject = this._form.querySelector(`[name='${labelFor}']`);
      create ? this._createObject(labelFor, inputObject, el) : this._clearSubmittedFormItem(inputObject);
    });
  };

  _createObject = (labelFor, inputObject, el) => {
    this._formData[labelFor] = {
      data: inputObject.value ? inputObject.value : "N/A",
      label: el.textContent.trim(),
    };
  };

  _clearFormEntries = () =>{
    this._formIteration(false);
  }

  _clearSubmittedFormItem = (inputObject) => {
    inputObject.value = "";
  };

  _createMessageHtml = () => {
    const submitBtn = Array.from(this._form.children).filter((el) => el.type === "submit")[0];
    const html = ` <div class="response-container">
                      <div class="${this._form.id}-success-fail response-message rs-hide"></div>
                   </div>`;

    submitBtn.insertAdjacentHTML("beforebegin", html);
    this._messageContainer = document.querySelector(`.${this._form.id}-success-fail`);
  };

  async _sendContactForm(formName) {
    const response = await axios
      .post("https://contactform.etvsoftware.com/api/v1/contactEmail", {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "Access-Control-Allow-Credentials": "true",
        },
        data: {
          ...this._formData,
        },
      })
      .then((res) => {
        document.querySelector(".response-message").textContent = res.data.message;
        return { status: res.status, message: res.data.message };
      })
      .catch((err) => {
        return { status: 500, message: err.message ? err.message : "Network error: Please try again later." };
      })
      .then((res) => res);
    return response;
  }

  _showResponse = (res, formName) => {
    const challenge = res.status < 200 || res.status > 299;
    const responseColor = challenge ? "rs-fail" : `rs-success`;

    this._messageContainer.textContent = challenge ? DEFAULT_SERVER_ERROR : res.message;
    this._messageContainer.classList.remove("rs-hide");
    this._messageContainer.classList.add(responseColor);
    setTimeout(() => {
      this._messageContainer.classList.add("rs-show");
    }, 50);

    setTimeout(() => {
      this._messageContainer.classList.remove("rs-show");
    }, 3250);

    setTimeout(() => {
      this._messageContainer.classList.add("rs-hide");
    }, 3500);
  };
}

(() => {
  Array.from(document.querySelectorAll("form")).map((form) => {
    const formInteraction = new FormMailer(form);
  });
})();
